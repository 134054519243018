import { faClipboardList } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import chiliCookOffRulesStyle from "assets/jss/material-kit-pro-react/views/chiliCookOffPageSections/chiliCookOffRulesStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardAvatar from "components/Card/CardAvatar.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(chiliCookOffRulesStyle)

export default function ChiliCookOffRules() {
  const { chiliCookOffRules1, chiliCookOffRules2 } = useStaticQuery(graphql`
    query chiliCookOffRulesImages {
      chiliCookOffRules1: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-11.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      chiliCookOffRules2: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-12.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={8} className={classes.aboutItem}>
            <Card profile className={classes.bottomCard}>
              <CardAvatar profile>
                <div className={classes.kleanTunnelIcon}>
                  <FontAwesomeIcon icon={faClipboardList} />
                </div>
              </CardAvatar>
              <CardBody>
                <h4 className={classes.cardTitle}>Chili Contest Rules</h4>
                <ol className={classes.chiliCookOffRules}>
                  <li>
                    Each contestant is encouraged to prepare three gallons (or
                    more) of chili. Remember, we lowered the registration fee
                    for this purpose. (Two gallon minimum.)
                  </li>
                  <li>
                    All contestants are REQUIRED to bring their own ingredients,
                    tables, cooking source and supplies. Electricity will not be
                    available.
                  </li>
                  <li>
                    All ingredients must come to the contest unprepared. Use of
                    home canned meats will not be allowed. Competition chili
                    must be cooked from scratch (starting with raw meat).
                  </li>
                  <li>
                    For CASI contestants: CASI judging will be a ‘CHILI GRIND’,
                    allowing for ground meat only. Commercial chili powder is
                    permissible, but complete commercial chili mixes that
                    contain several pre-measured spices, are NOT permitted.
                    There will be no fillers in the chili, i.e. beans, macaroni,
                    rice, etc.
                  </li>
                  <li>
                    Any ingredients needing to be kept cool must arrive at the
                    contest in coolers and be kept cool until used.
                  </li>
                  <li>
                    One chili per cook, per competition. You can compete in both
                    CASI and traditional.
                  </li>
                  <li>
                    You may have to taste your own chili. All chili cooks may be
                    required, at the discretion of the Referee or Head judge, to
                    remove the lid from the chili cup and eat a spoonful of
                    their own chili before turning it in for judging. If the
                    contestant refuses, the chili will be automatically
                    disqualified.
                  </li>
                  <li>
                    Contestants are encouraged to decorate their area in a
                    “theme”, but are not required to do so.
                  </li>
                  <li>
                    Cook-off participants are forbidden from selling any type of
                    products from their cook-off booth without written consent
                    from Chilimania, Inc.
                  </li>
                  <li>
                    Per our liquor license with the City of Edgerton, Chilimania
                    cooks are not allowed to serve alcoholic beverages to the
                    general public. Also, please keep in mind that Chilimania is
                    a family event.{" "}
                    <b>
                      We want you to have fun, but obvious public intoxication
                      or lewdness will be frowned upon.
                    </b>
                  </li>
                </ol>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.aboutItem}>
            <GatsbyImage
              image={chiliCookOffRules1.childImageSharp.gatsbyImageData}
              alt="Chili Cook-off Rules Photo 1"
              className={classes.chiliCookOffRulesImage}
            />
            <GatsbyImage
              image={chiliCookOffRules2.childImageSharp.gatsbyImageData}
              alt="Chili Cook-off Rules Photo 2"
              className={classes.chiliCookOffRulesImage}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
