import {
  cardTitle,
  container,
  description,
  section,
  title,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const imgRounded = imagesStyles.imgRounded

const chiliCookOffRulesStyle = (theme) => ({
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
  },
  cardTitle,
  consultationFormButton: {
    float: "left",
    "& span": {
      lineHeight: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginBottom: "2rem",
    },
  },
  aboutIcon: {
    maxWidth: "100%",
  },
  aboutItem: {
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  aboutTitle: {
    ...title,
    textAlign: "left",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  container,
  description,
  section: {
    ...section,
    padding: "120px 0px 70px 0px",
    textAlign: "center",
  },
  chiliCookOffRules: {
    ...description,
    textAlign: "left",
    marginBottom: "2rem",
    "& li": {
      margin: "1.5rem 0rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
    },
  },
  servicesAboutListTitle: {
    ...description,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  servicesDescription: {
    ...description,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  kleanTunnelIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px !important",
    height: "100px",
    backgroundColor: "white",
    fontSize: "3rem",
  },
  chiliCookOffRulesImage: {
    margin: "2rem 1rem",
    ...imgRaised,
    ...imgRounded,
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
})

export default chiliCookOffRulesStyle
